







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class VCopy extends Vue {
  @Prop({ default: undefined, type: String, required: false })
  readonly value!: string | undefined;

  isDone = false;

  timeoutId: number | undefined;

  copy(): void {
    if (!this.value) return;
    window.navigator.clipboard
      .writeText(this.value)
      .then(() => {
        this.isDone = true;
      })
      .catch(() => {
        this.isDone = false;
      })
      .finally(() => {
        this.release();
      });
  }

  release(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(() => {
      this.isDone = false;
    }, 1000);
  }
}
