var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("a-icon", {
        attrs: { type: _vm.isDone ? "check" : "copy" },
        on: { click: _vm.copy }
      }),
      _vm._v(" " + _vm._s(_vm.$t("common.copy")) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }