var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_stock_report") } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                model: _vm.formModel,
                rules: _vm.formRules,
                "wrapper-col": { span: 12 },
                "label-col": { span: 6 },
                "label-align": "left"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_part_number"),
                            prop: "productCode"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "help",
                              fn: function() {
                                return [
                                  _c("VCopy", {
                                    attrs: { value: _vm.formModel.productCode }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("SelectProduct", {
                            on: { "update:meta": _vm.onChangeProduct },
                            model: {
                              value: _vm.formModel.productCode,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "productCode", $$v)
                              },
                              expression: "formModel.productCode"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_part_name"),
                            prop: "productName"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "help",
                              fn: function() {
                                return [
                                  _c("VCopy", {
                                    attrs: { value: _vm.formModel.productName }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("SelectProduct", {
                            attrs: { "option-label": "name" },
                            on: { "update:meta": _vm.onChangeProduct },
                            model: {
                              value: _vm.formModel.productName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "productName", $$v)
                              },
                              expression: "formModel.productName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_uom"), prop: "uomId" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "allow-clear": "",
                                "show-search": "",
                                "option-filter-prop": "children",
                                "filter-option": _vm.useLocalFilter,
                                loading: _vm.loading.uom
                              },
                              on: { change: _vm.onChangeUom },
                              model: {
                                value: _vm.formModel.uomId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "uomId", $$v)
                                },
                                expression: "formModel.uomId"
                              }
                            },
                            _vm._l(_vm.optUom, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [
                                  _c("a-tooltip", [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "title" },
                                        slot: "title"
                                      },
                                      [_vm._v(" " + _vm._s(item.label) + " ")]
                                    ),
                                    _vm._v(" " + _vm._s(item.label) + " ")
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_date"), prop: "date" } },
                        [
                          _c("a-range-picker", {
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              type: "date"
                            },
                            model: {
                              value: _vm.formModel.date,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "date", $$v)
                              },
                              expression: "formModel.date"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            "label-align": "right",
                            label: _vm.$t("lbl_branch"),
                            prop: "branch"
                          }
                        },
                        [
                          _c("SelectBranch", {
                            on: { "update:meta": _vm.onChangeBranch },
                            model: {
                              value: _vm.formModel.branchId,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "branchId", $$v)
                              },
                              expression: "formModel.branchId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            "label-align": "right",
                            label: _vm.$t("lbl_warehouse"),
                            prop: "warehouse"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "allow-clear": "",
                                "show-search": "",
                                dropdownMatchSelectWidth: false,
                                "filter-option": false,
                                loading: _vm.loading.warehouse
                              },
                              on: {
                                search: _vm.onSearchWarehouse,
                                change: _vm.onChangeWarehouse
                              },
                              model: {
                                value: _vm.formModel.warehouse,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "warehouse", $$v)
                                },
                                expression: "formModel.warehouse"
                              }
                            },
                            _vm._l(_vm.optWarehouse, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [
                                  _c("a-tooltip", [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "titlte" },
                                        slot: "titlte"
                                      },
                                      [_vm._v(" " + _vm._s(item.label) + " ")]
                                    ),
                                    _vm._v(" " + _vm._s(item.label) + " ")
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            "label-align": "right",
                            label: _vm.$t("lbl_rack"),
                            prop: "location"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "allow-clear": "",
                                "show-search": "",
                                dropdownMatchSelectWidth: false,
                                "filter-option": false,
                                loading: _vm.loading.location
                              },
                              on: {
                                search: _vm.onSearchLocation,
                                change: _vm.onChangeLocation
                              },
                              model: {
                                value: _vm.formModel.location,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "location", $$v)
                                },
                                expression: "formModel.location"
                              }
                            },
                            _vm._l(_vm.optLocation, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [
                                  _c("a-tooltip", [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "titlte" },
                                        slot: "titlte"
                                      },
                                      [_vm._v(" " + _vm._s(item.label) + " ")]
                                    ),
                                    _vm._v(" " + _vm._s(item.label) + " ")
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-space",
                        [
                          _c("a-button", { on: { click: _vm.handleReset } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                          ]),
                          _c(
                            "a-button",
                            {
                              attrs: {
                                "html-type": "submit",
                                type: "primary",
                                loading: _vm.loading.find
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.dataReport.data,
              size: "small",
              "row-key": function(r, i) {
                return i
              },
              loading: _vm.loading.find,
              scroll: { y: 728, x: "calc(100% + 500px)" },
              pagination: false
            },
            scopedSlots: _vm._u([
              {
                key: "nullable",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                }
              },
              {
                key: "number",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
                }
              },
              {
                key: "date",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
                }
              },
              {
                key: "detail",
                fn: function(text, record) {
                  return [
                    !!text
                      ? _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: {
                              click: function($event) {
                                return _vm.showAsset(record)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(text) + " ")]
                        )
                      : _c("span", [_vm._v(_vm._s("-"))])
                  ]
                }
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "a-descriptions",
                      { attrs: { size: "small", column: 4 } },
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: _vm.$t("lbl_beginning_balance") } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.dataReport.beginningBalance
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: _vm.$t("lbl_ending_balance") } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.dataReport.endingBalance
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: _vm.$t("lbl_total_qty_in") } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(_vm.dataReport.totalQtyIn)
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: _vm.$t("lbl_total_qty_out") } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(_vm.dataReport.totalQtyOut)
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("div", { staticClass: "mt-2 text-right" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("lbl_total_items", {
                    total: _vm.dataReport.data.length
                  })
                ) +
                " "
            )
          ]),
          _c(
            "div",
            { staticClass: "mt-5 text-right" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading.download },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ModalAsset", {
        attrs: { visible: _vm.modal.show, "data-view": _vm.modal.data },
        on: {
          ok: function($event) {
            return _vm.modal.toggle()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }